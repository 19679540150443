<div fxLayout='column' id="toast-container" ngClass.xs="w100p" fxLayoutAlign.xs='center center'>
  <ng-container *ngFor="let toast of toastList">
      <div fxLayout='column' class="toast-card-style" fxLayoutAlign.xs='center center'
          *ngIf="toast.type === config.error || toast.type === config.success || toast.type === config.save || toast.type === config.saved">
          <div fxLayout='row'>
              <div fxLayout='column' fxLayoutAlign='center center' *ngIf="toast.type === config.error">
                  <mat-icon class="toast-icon-style error-icon-style">error</mat-icon>
              </div>
              <div fxLayout='column' fxLayoutAlign='center center' *ngIf="toast.type === config.success">
                  <mat-icon class="success-icon-style toast-icon-style">check_circle</mat-icon>
              </div>
              <div fxLayout='column' class="pr10" fxLayoutAlign='center center' *ngIf="toast.type === config.save">
                  <mat-spinner class="loading"></mat-spinner>
              </div>
              <div fxLayout='column' fxLayoutAlign='center center' *ngIf="toast.type === config.saved">
                  <mat-icon class="success-icon-style toast-icon-style">done</mat-icon>
              </div>
              <div fxLayout='column' fxLayoutAlign.xs='center center'>
                  <div fxLayout='row' class="toast-header-style">
                      {{toast.headerMessage}}
                  </div>
                  <div fxLayout='row' class="toast-content-style" fxHide.xs>
                      {{toast.message}}
                  </div>
              </div>
          </div>
          <div fxLayout='row' fxLayoutAlign.xs='center center' *ngIf="toast.isShowMobileContent" fxHide.gt-xs>
              <div fxLayout='row' class="toast-content-style">
                  {{toast.message}}
              </div>
          </div>
      </div>
      <div fxLayout='column' class="toast-card-style" ngClass.gt-xs="minh72px" fxLayoutAlign.xs='center center'
          *ngIf="toast.type === config.download">
          <div fxLayout='row'>
              <div fxLayoutAlign='center center'>
                  <mat-icon class="saving-icon-style toast-icon-style">cached</mat-icon>
              </div>
              <div fxLayout='column' fxLayoutAlign.xs='center center'>
                  <div fxLayout='row' class="toast-header-style">
                      {{toast.headerMessage}}
                  </div>
                  <div fxLayout='row' class="toast-content-style" fxHide.xs>
                      {{toast.message}}
                  </div>
              </div>
          </div>
          <div fxLayout='row' fxLayoutAlign='start center' fxHide.xs>
              <mat-progress-bar mode="determinate" value="{{toast.progress}}" class="progress-icon-style">
              </mat-progress-bar>
          </div>
      </div>
  </ng-container>
</div>
