import { CommonService } from 'src/app/shared/services/common.service';
import { Component, Input, OnInit } from '@angular/core';
import { Constants } from 'src/app/app.constant';

@Component({
  selector: 'common-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  toast: any = {
    type: '',
    headerMessage: '',
    message: '',
    mobileHeaderMessage: '' //For downloading only
  };
  toastInterval:any;
  @Input() toastList: any;
  constructor(private commonService: CommonService) {

  }
  config = Constants.toastConfig;

  ngOnInit(): void {
    this.toastInterval = setInterval(() => {
      if(this.toastList.length > 0){
        this.toastList.forEach((toast:any, index:number) => {
          let timeDiff = new Date().getTime() - toast.currentTime;
          if((toast.type !== this.config.download || toast.progress === 100) && timeDiff >= 10000) {
            this.toastList.splice(index,1);
          }
        });
      } else {
        this.hideToast();
      }
    }, 1000);
  }

  hideToast(){
    let toastObj:any = {};
    toastObj.showToast = false;
    this.commonService.setToastMessage(toastObj);
    clearInterval(this.toastInterval)
  }

}
