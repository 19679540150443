import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { ToasterService } from '../services/toaster.service';
import { Constants } from 'src/app/app.constant';
import { environment } from 'src/environments/environment';
import { GlobalSettingsService } from "../../core/layout/service/global.settings.service";

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  globalSettings: any;

  constructor(
    private msalService: MsalService,
    private toaster: ToasterService,
    private globalSettingsService: GlobalSettingsService
  ) {
    this.globalSettings = this.globalSettingsService.getSettings();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authHeader = 'Bearer ' + localStorage.getItem('accessToken') || '';

    let httpRequest = request.clone({
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Authorization': authHeader
      })
    });

    return next.handle(httpRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (
            !this.globalSettings ||
            !this.globalSettings.hasOwnProperty('allowSilentTokenRefresh') ||
            this.globalSettings.allowSilentTokenRefresh === null ||
            this.globalSettings.allowSilentTokenRefresh === undefined ||
            this.globalSettings.allowSilentTokenRefresh
          ) {
            // New mechanism: Handle unauthorized errors by silently acquiring a new token
            return this.handle401Error(httpRequest, next);
          } else {
            // Old mechanism: Logout
            this.logout();
            return throwError(() => new HttpErrorResponse({ error: 'Session expired and logged out.' }));
          }
        }
        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const silentRequest = {
      scopes: ['user.read'],
      authority: `${environment.authority}${environment.tenantId}`,
    };

    return this.msalService.acquireTokenSilent(silentRequest)
      .pipe(
        catchError(error => {
          // If silent refresh fails, logout
          this.logout();
          return throwError(() => error);
        }),
        switchMap((tokenResponse: AuthenticationResult) => {
          localStorage.setItem('accessToken', tokenResponse.idToken);
          const authorizedRequest = request.clone({
            headers: request.headers.set('Authorization', 'Bearer ' + tokenResponse.idToken)
          });
          // Handle the authorized request and check for another 401
          return next.handle(authorizedRequest).pipe(
            catchError((error: HttpErrorResponse) => {
              if (error.status === 401) {
                // If the token is still not accepted, logout
                this.logout();
                return throwError(() => new HttpErrorResponse({ error: 'Session expired even after token refresh.' }));
              }
              return throwError(() => error);
            })
          );
        })
      );
  }

  logout() {
    this.toaster.showToast(Constants.toastConfig.error, "Session Expired!!", `Session Got Expired!!`);
    this.msalService.logout();
    localStorage.clear();
    sessionStorage.clear();
    this.toaster.showToast(Constants.toastConfig.success, "Session Expired!!", `Logout successfully!!`);
  }
}
