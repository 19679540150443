<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- <router-outlet></router-outlet>

<div *ngIf="showToast">
  <common-toast [toastList]="list"></common-toast>
</div> -->

<div fxLayout='column' id='app-container'>
  <div class='h99vh' *ngIf="loadPage">
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="showToast">
    <common-toast [toastList]="list"></common-toast>
  </div>
</div>
