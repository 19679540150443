import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RestService } from 'src/app/shared/services/rest.service';
import { API_END_POINT } from 'src/app/app.constant';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GlobalSettingsService {

  private settings: any;
  private settingsChanged: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private readonly restService: RestService) { }

  getSettingsChangedObservable() {
    return this.settingsChanged.asObservable();
  }

  getGlobalSettings(): any {
    const url = `${API_END_POINT.GLOBAL_SETTINGS}`;
    return this.restService.get(url).pipe(map(response => {
      this.setSettings(response);  // set and notify the change
      return response;
    }));
  }

  updateGlobalSettings(settings: any): any {
    const url = `${API_END_POINT.GLOBAL_SETTINGS}`;
    return this.restService.put(url, settings).pipe(map(response => {
      this.setSettings(response);  // set and notify the change
      return response;
    }));
  }

  setSettings(newSettings: any) {
    this.settings = newSettings;
    this.settingsChanged.next(newSettings);
  }

  getSettings() {
    return this.settings;
  }
}
