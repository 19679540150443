export enum API_END_POINT {
  SLASH = '/',
  LOADBOARD = "quotes",
  TRANSACTIONS = "transactions",
  RANGE = 'range',
  USERS = 'users',
  USER = "user",
  QUOTE="quote",
  BID="bid",
  DECLINE="decline",
  COMPANY="company",
  ME="me",
  FILTER='filter',
  EQUIPMENT='equipment',
  ACCESSORIAL='accessorial',
  GLOBAL_SETTINGS = 'global-settings',
  ORDER_NUMBERS = "order-numbers",
  COMPANY_NAMES = "customer-names",
  CUSTOMERS = "customers"
}

export enum CurrentUser {
  ADMIN_ID = 'admin',
  READ_ONLY_USER = 'read only'
}

export class Constants {
  static toastConfig = {
    success: 'success',
    error: 'error',
    download: 'download',
    save: 'save',
    saved: 'saved',
    duration: 2000,
  };
}
