import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '../../services/common.service';

@Pipe({
  name: 'status',
  pure: true
})
export class StatusPipe implements PipeTransform {

  constructor(private readonly commonService: CommonService) {
  }
  
  transform(value: unknown, ...args: unknown[]): any {
    const count = this.commonService.expiryDays(value);
    switch (true) {
      case count>1:
        return count + ' Days'
        case count==1 || count==0:
        // return count + ' Day';
       return this.getTimeInterval(value)
      default:
        break;
    }
  }

  getTimeInterval(value: any) {
    const date1 = new Date();
    const date2 = new Date(value);
    const msBetweenDates = date2.getTime() - date1.getTime();

    let diffSeconds = Math.floor(msBetweenDates / 1000);
    let diffMinutes = Math.floor(diffSeconds / 60);
    let diffHours = Math.floor(diffMinutes / 60);
    diffSeconds = diffSeconds % 60;
    diffMinutes = diffMinutes % 60;
    diffHours= diffHours % 24
    
    if(diffHours>0){
      return diffHours+' hrs'
    }else{
      return diffMinutes+' min'
    }
  }

}
