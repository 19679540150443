import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(private readonly http: HttpClient) { }

  save(url: string, postData?: any): any {
    return this.http.post(`${environment.apiUrl}${url}`, postData, {
      headers: new HttpHeaders({ 'Access-Control-Allow-Methods': 'POST' })
    });
  }
  post(url: string, postData: any): any {
    return this.http.post(`${environment.apiUrl}${url}`, postData, {
      headers: new HttpHeaders({ 'Access-Control-Allow-Methods': 'POST' })
    });
  }

  get(url: string): any {
    return this.http.get(`${environment.apiUrl}${url}`, {
      headers: new HttpHeaders({ 'Access-Control-Allow-Methods': 'GET' })
    });
  }

  put(url: string, data: any): any {
    return this.http.put(`${environment.apiUrl}${url}`, data, {
      headers: new HttpHeaders({ 'Access-Control-Allow-Methods': 'PUT' })
    });
  }

  delete(url: string): any {
    return this.http.delete(`${environment.apiUrl}${url}`, {
      headers: new HttpHeaders({ 'Access-Control-Allow-Methods': 'DELETE' })
    });
  }

  patch(url: string, data: any): any {
    return this.http.patch(`${environment.apiUrl}${url}`, data, {
      headers: new HttpHeaders({ 'Access-Control-Allow-Methods': 'PATCH' })
    });
  }


  fileUpload(url: string, formData: any): any {
    return this.http.post(`${environment.apiUrl}${url}`, formData, {
      headers: new HttpHeaders({ 'Access-Control-Allow-Methods': 'POST', 'content-type': 'multipart/form-data;application/json' })
    });
  }
  filePatch(url: string, formData: any): any {
    return this.http.patch(`${environment.apiUrl}${url}`, formData, {
      headers: new HttpHeaders({ 'Access-Control-Allow-Methods': 'PATCH', 'content-type': 'multipart/form-data;application/json' })
    });
  }
  fileDownload(url: string): any {
    return this.http.get(`${environment.apiUrl}${url}`, { responseType: 'blob' as 'json', observe: 'response' })
  }

  getPlainText(url: string): any {
    return this.http.get(`${environment.apiUrl}${url}`, {
      headers: new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8'), responseType: 'text'
    });
  }

  export(url: string) {
    return this.http.get(`${environment.apiUrl}${url}`,
      {
        observe: 'response', responseType: 'blob',
        headers: new HttpHeaders({ 'Access-Control-Allow-Methods': 'GET' })
      })
  }

}
