import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private messageSubject = new Subject<any>();
  getMessage = this.messageSubject.asObservable();
  private showToast = new Subject<any>();
  displayToast = this.showToast.asObservable();
  private currentUser = new Subject<any>();
  getCurrentUser = this.currentUser.asObservable();
  public loginUserDetails: any
  constructor() { }

  getDistanceBasedOnLatAndLon(lat1: number, lon1: number, lat2: number, lon2: number, unit?: string) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radLat1 = Math.PI * lat1 / 180;
      var radLat2 = Math.PI * lat2 / 180;
      var theta = lon1 - lon2;
      var radTheta = Math.PI * theta / 180;
      var distance = Math.sin(radLat1) * Math.sin(radLat2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
      if (distance > 1) {
        distance = 1;
      }
      distance = Math.acos(distance);
      distance = distance * 180 / Math.PI;
      distance = distance * 60 * 1.1515;
      if (unit == "K") { distance = distance * 1.609344 } //K kilometer
      if (unit == "N") { distance = distance * 0.8684 } // N
      return Math.round(distance);
    }
  }

  /* @param {string} date_string - date in m/d/y format
  ** @returns {number} days between today and expiry
  */
  expiryDays(date_string: any) {
    const date1 = new Date();
    const date2 = new Date(date_string);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }

  setUserDetails(userDetails: any): void {
    this.currentUser.next(userDetails)
  }
  setMessage(message: any): void {
    this.messageSubject.next(message)
  }
  // To set and get toast message obj
  setToastMessage(message: any) {
    this.showToast.next(message);
  }
  setCurrentUser(currentUser: any): void {
    this.currentUser.next(currentUser)
  }

  setLoginUserDetails(user: any) {
    this.loginUserDetails = user;
  }
  getLoginUserDetails() {
    return this.loginUserDetails;
  }

}
