import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { CommonService } from './shared/services/common.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';

import { filter, Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'loadBoard-ui';
  user: any;
  showToast: boolean = false;
  loadPage: boolean = true;
  list: any = [];
  private readonly _destroying$ = new Subject<void>();
  constructor(private commonService: CommonService,
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService,

  ) {
    this.commonService.displayToast.subscribe(data => {
      this.showToast = data.showToast;
      if (data.toast) {
        data.toast.currentTime = new Date().getTime();
        const foundObjectIndex = this.list.findIndex((obj: any) => obj.id && (obj.id === data.toast.id));
        if (foundObjectIndex !== -1) {
          this.list[foundObjectIndex] = {};
          this.list[foundObjectIndex] = data.toast;
        } else {
          this.list.push(data.toast);
        }

      }
    });
    this.user = {};
  }

  public ngOnInit() {
    // this.authService.isAuthenticated$.subscribe(async (isLoggedIn) => {
    //   if (isLoggedIn) {
    //     await this.authService.getAccessTokenSilently().subscribe(response => {
    //       localStorage.setItem("accessToken", response)
    //     }
    //     );
    //     this.authService.user$.subscribe((success: any) => {
    //       this.user = success;
    //       localStorage.setItem('nickname', this.user?.nickname);
    //     });
    //   }
    // });



    this.broadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    ).subscribe(() => {
      this.setAuthStatus();
      this.extractMSALToken();
    })
    this.broadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      takeUntil(this._destroying$)
    )
      .subscribe((result: EventMessage) => {
        const authResult = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(authResult.account);

      });

  }

  setAuthStatus(): void {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      activeAccount = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(activeAccount);
    }

  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


  extractMSALToken() {
    let token = null
    for (const key of Object.keys(localStorage)) {
      if (key.includes('idtoken')) {
        const val: any = JSON.parse(localStorage.getItem(key)!)
        if (val.credentialType ==='IdToken') {
          // We have a (possibly expired) token
          if (val.secret) {
            //console.log(key)
            // Found the correct token
            token = val.secret
            localStorage.setItem("accessToken",token);
          }
          else {
            //console.log('will remove ' + key)
            // Clear old data
            localStorage.removeItem(key)
          }
        }
      }
    }
    if (token) return token
    throw new Error('No valid token found')
  }
}
