import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LoginComponent } from './core/auth/login/login.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { FailedComponent } from './core/auth/failed/failed.component';
import {ContactadminComponent} from "./core/layout/contactadmin/contactadmin.component";
import {OnlyAdminComponent} from "./core/layout/only-admin/only-admin.component";

const routes: Routes = [
  {
    path: 'only-admin',
    component: OnlyAdminComponent
  },
  {
    path: 'contact-admin',
    component: ContactadminComponent
  },
  {
    path: 'auth',
    component: MsalRedirectComponent
  },
  {
    path: 'dashboard',
    canActivate: [MsalGuard],
    loadChildren: () => import('./core/layout/layout.module').then(m => m.LayoutModule),
  },
  {
    path: 'login-failed',
    component: FailedComponent
  },
  {
    path: '',
    redirectTo: '/dashboard/list-view-page',
    pathMatch: 'full',
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
    }),
    MatSidenavModule],
  exports: [RouterModule, MatSidenavModule]
})
export class AppRoutingModule { }
